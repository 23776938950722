<template>
    <div class="respective_content" id="plan_index">
        <div class="acton_header">
            <div class="left_section">
                <h2>Workout Programs</h2>
                <div class="search_area">
                    <input type="text" placeholder="Search" @input="isTyping = true" v-model.trim="search"/>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                <button type="button" class="add_btn pointer" @click="newWorkoutPlan = true"><i class="fas fa-plus"></i>Add A Plan</button> -->
            </div>
            <div class="right_section">
                <ul>
                    <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                    <li @click="isLibrary = true;" v-tooltip="`Manage Exercise Library`" position="bottom"><img src="@/assets/images/library2.svg"></li>
                    <li @click="sortWorkoutPlan = true;" v-tooltip="`Sort Programs Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                    <li @click="importWorkoutPlan = true;" v-tooltip="`Import a shared plan`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    <li @click="manageCategories = !manageCategories" v-tooltip="`Manage Categories`" position="bottom"><img src="@/assets/images/smart.svg"></li>
                    <li class="add_btn light pointer" @click="newWorkoutPlan = true"><i class="fas fa-plus-circle"></i>Create A Program</li>
                </ul>
            </div>
        </div>
        <div class="result_wpr new">
            <div class="actions">
                <ul class="left">
                    <li class="p-0" v-show="selectedPlans.length || showContent">
                        <label for="check" class="checkbox">
                            <h5 class="p-0">{{ selectedPlans.length ? `${selectedPlans.length} Selected` : `Select ${tab}s for bulk action`}}</h5>
                        </label>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="bulkOpt = !bulkOpt" v-if="selectedPlans.length" v-click-outside="closeBulkActionDropdown">
                        Bulk Action<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="bulkOpt ? 'active' : ''">
                            <ul>
                                <li @click="handleAssignContacts(selectedPlans)">Assign Contacts</li>
                                <li @click="handleUnassignContacts(selectedPlans)">Unassign Contacts</li>
                                <li class="danger" @click="handleDeletePlan(selectedPlans)">Delete</li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                        <label>Tutorials</label><img src="@/assets/images/play.svg">
                        <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                            <ul>
                                <li @click="tutorial = true;">How to use the plans feature</li>
                            </ul>
                        </div>
                    </li>
                    <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" @input="isTyping = true" v-model.trim="search"/>
                        <button class="search_btn" @click="searchField = !searchField;">
                        <i class="fas fa-search"></i>
                        </button>
                    </li> -->
                </ul>
                <ul class="right">
                    <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                            <ul>
                                <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                    {{ filter.title }}
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                        <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="workout-plan-per-page-filter" />
                    </li>
                    <li class="filter_btn" @click="mobile_filter = true;">
                        <span></span>
                        <span></span>
                        <span></span>
                    </li>
                </ul>
            </div>
            <ul class="tab_sec">
                <li :class="tab === 'workouts' ? 'active' : ''" @click="switchTab('workouts')">My Workouts <sup>&nbsp;</sup></li>
                <li :class="tab === 'plan' ? 'active' : ''" @click="switchTab('plan')">My Programs <sup>&nbsp;</sup><!-- <sup class="workout-plan-beta">Beta</sup>--></li>
            </ul>
            <div class="listing_loader" v-if="workoutPlanLoader"><quote-loader /></div>
            <div class="action_content" v-show="!workoutPlanLoader">
                <asset-folder v-model="assetFolder" title="Workout Plan" asset-type="workout-plans" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :update-breadcrumb="updateFolderBreadcrumb" :section-breadcrumb="folderBreadcrumb['workout-plans']"/>
                <ul class="playbook_list">
                    <li v-for="(plan, p) in plans.data" :key="p">
                        <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                            <div class="item_img" :class="{ active : selectedPlans.includes(plan.id) }">
                                <img v-if="plan.thumb" :src="plan.thumb" class="img">
                                <img v-else src="@/assets/images/thumb/default-habit.svg" class="img">
                                <span v-if="companyUserCan('update', 'workouts')" class="replace_img" @click="editThumbnail = true; activeWorkoutPlan = plan;">
                                    <img src="@/assets/images/image.svg">
                                </span>
                                <!-- <div class="switch_list">
                                    <label :for="`client_tease-${plan.id}`" class="switch_option capsule_btn border-0 py-1 m-0" @click="!has_tease ? handleTeaseConfirmation() : null">
                                        <h5 class="mr-1">Tease</h5>
                                        <input type="checkbox" :id="`client_tease-${plan.id}`" :true-value="1" :false-value="0" v-model="has_tease" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div> -->
                                <ul class="statistics" v-if="plan.is_private">
                                    <li>
                                        <label :for="`plan-${plan.id}`" class="checkbox">
                                        <input type="checkbox" :id="`plan-${plan.id}`" :value="plan.id" v-model="selectedPlans" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </li>
                                    <li><span class="pp-label">{{ plan.is_private ? 'Private' : 'Public'}}</span></li>
                                    <li>Assigned: {{ plan.assign_count ? plan.assign_count : 0 }}</li>
                                    <li>Completed: {{ plan.responses_count }}</li>
                                    <li>Completion Rate: {{ plan.completion_rate }}%</li>
                                    <li>
                                        Overall Rating:
                                        <star-rating
                                            :increment="0.01"
                                            :max-rating="5"
                                            :rating="plan.ratings"
                                            active-color="#2f7eed"
                                            active-border-color="#2f7eed"
                                            :border-width="1"
                                            border-color="#c5c5c5"
                                            :show-rating="false"
                                            :star-size="8"
                                            :read-only="true"
                                            :rounded-corners="true"
                                            inactive-color="#c5c5c5"
                                        ></star-rating>
                                    </li>
                                </ul>
                                <ul class="statistics" v-else>
                                    <li>
                                        <label :for="`plan-${plan.id}`" class="checkbox">
                                        <input type="checkbox" :id="`plan-${plan.id}`" :value="plan.id" v-model="selectedPlans" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </li>
                                    <li><span class="pp-label">{{ plan.is_private ? 'Private' : 'Public'}}</span></li>
                                    <li>Unique Views: {{ plan.views_count }}</li>
                                    <li>Opt-ins: {{ plan.optins_count }}</li>
                                    <li>Opt-in Rate: {{ plan.optins_rate }}%</li>
                                    <li>Completions: {{ plan.responses_count }}</li>
                                    <li>
                                        Overall Rating:
                                        <star-rating
                                            :increment="0.01"
                                            :max-rating="5"
                                            :rating="plan.ratings"
                                            active-color="#2f7eed"
                                            active-border-color="#2f7eed"
                                            :border-width="1"
                                            border-color="#c5c5c5"
                                            :show-rating="false"
                                            :star-size="8"
                                            :read-only="true"
                                            :rounded-corners="true"
                                            inactive-color="#c5c5c5"
                                        ></star-rating>
                                    </li>
                                </ul>
                            </div>
                            <div v-tooltip="plan.name">
                                <a class="item_ttl pointer" v-if="companyUserCan('update', 'workouts')" @click="toggleWorkoutPlanWizard(plan)">{{ plan.name }}</a>
                                <a class="item_ttl pointer" v-else>{{ plan.name }}</a>
                            </div>
                            <div class="item_info">
                                <div class="member pointer" @click="manageContact = true; activeWorkoutPlan = plan;" v-tooltip="`Clients Assigned to this Program`">
                                    <img src="@/assets/images/member.svg">
                                    {{ plan.assign_count }} Assigned
                                </div>
                                <ul>
                                    <li @click="handleAnalytics(plan)"><span v-tooltip="`Open Analytics`"><i class="fas fa-chart-line"></i></span></li>
                                    <li @click="workoutPlanLink = true; activeWorkoutPlan = plan;"><span v-tooltip="`Live Program Link`"><i class="fas fa-link"></i></span></li>
                                    <li @click="moreOption(plan.id)">
                                        <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                        <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  plan.id}">
                                            <li @click="toggleWorkoutPlanWizard(plan)" v-if="companyUserCan('update', 'workouts')">Edit</li>
                                            <li @click="renamePlan = true; activeWorkoutPlan = plan;">Rename</li>
                                            <li @click="manageContact = true; activeWorkoutPlan = plan;">Manage</li>
                                            <li @click="preview = true; activeWorkoutPlan = plan;">Preview</li>
                                            <li @click="handleAnalytics(plan)">Analytics</li>
                                            <li @click="handleShareWorkoutPlan(plan)">Share</li>
                                            <li @click="handleAssignContacts([plan.id])">Assign Contacts</li>
                                            <li @click="handleDuplicatePlan([plan.id])">Duplicate</li>
                                            <li class="danger" @click="handleDeletePlan([plan.id])">Delete</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="empty_section" v-if="plans.total == 0">
                    <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                    <h1>Looks like you don't have any Plans yet. Click the play button to get started.</h1>
                </div> -->
                <div class="empty_box" v-if="plans.total == 0">
                    <img src="@/assets/images/empty_state.svg">
                    <h4>Looks like you don't have any Programs yet. Click the play button to get started.</h4>
                </div>
            </div>
            <div class="list_footer">
                <ul>
                    <li>{{ plans.from ? plans.from : 0 }} - {{ plans.to ? plans.to : 0 }} of {{ plans.total ? plans.total : 0 }}</li>
                </ul>
            </div>
            <div class="pagination" v-show="plans.total">
                <pagination v-model="page" :pages="plans.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="header_filter" :class="{'show' : mobile_filter}">
            <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
            <div class="top_area">
                <h3>Actions</h3>
                <ul>
                    <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                    <li @click="isLibrary = true;" v-tooltip="`Manage Exercise Library`" position="bottom"><img src="@/assets/images/library2.svg"></li>
                    <li @click="sortWorkoutPlan = true;" v-tooltip="`Sort Programs Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                    <li @click="importWorkoutPlan = true;" v-tooltip="`Import a shared plan`" position="bottom"><img src="@/assets/images/import.svg"></li>
                </ul>
            </div>
            <div class="action_area">
                <h3 class="sub_heading2">Filters</h3>
                <div class="search_area">
                    <input type="text" placeholder="Search" @input="isTyping = true" v-model.trim="search"/>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <ul>
                    <li v-show="selectedPlans.length || showContent">
                        <label for="check" class="checkbox">
                            <h5 class="p-0">{{ selectedPlans.length ? `${selectedPlans.length} Selected` : `Select ${tab}s for bulk action`}}</h5>
                        </label>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="bulkOpt2 = !bulkOpt2" v-if="selectedPlans.length" v-click-outside="closeBulkActionDropdown2">
                        Bulk Action<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="bulkOpt2 ? 'active' : ''">
                            <ul>
                                <li @click="handleAssignContacts(selectedPlans)">Assign Contacts</li>
                                <li @click="handleUnassignContacts(selectedPlans)">Unassign Contacts</li>
                                <li class="danger" @click="handleDeletePlan(selectedPlans)">Delete</li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                            <ul>
                                <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                    {{ filter.title }}
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="modal secondary prompt" v-if="teaseConformation">
        <div class="modal_container">
            <img src="@/assets/images/logo2.svg" alt="" class="logo_img">
            <div class="setting_wpr">
                <div class="optin_type border-bottom">
                    <h4>Free <span>Your client will be redirected to the asset optin page or a URL of your choice.</span></h4>
                    <label for="free_teaser" class="switch_option capsule_btn">
                        <input type="radio" name="tease_type" id="free_teaser" :value="0" v-model="is_premium_teaser" hidden>
                        <div><span></span></div>
                    </label>
                </div>
                <div class="optin_type">
                    <h4>Paid <span>Your client will be redirected to the asset checkout page or a URL of your choice.</span></h4>
                    <label for="paid_teaser" class="switch_option capsule_btn">
                        <input type="radio" name="tease_type" id="paid_teaser" :value="1" v-model="is_premium_teaser" hidden>
                        <div><span></span></div>
                    </label>
                </div>
                <div class="form_grp pt-5">
                    <div class="group_item">
                        <label class="input_label">Please select a redirect URL <a class="option" @click="is_optin_url = !is_optin_url">{{is_optin_url ? 'Add from list' : '+ Add URL'}}</a></label>
                        <div class="field_wpr has_prefix" v-if="is_optin_url">
                            <input type="text" name="url" placeholder="https://thrivecoach.io/optin" v-model="optinURL" />
                            <span class="prefix">URL</span>
                        </div>
                        <div class="field_wpr" v-if="is_premium_teaser == 1 && !is_optin_url">
                            <Multiselect v-model="selectOptinItem" placeholder="Choose an opt-in" :options="['Premium Optin 1', 'Premium Optin 2', 'Premium Optin 3', 'Premium Optin 4']"></Multiselect>
                        </div>
                        <div class="field_wpr" v-if="is_premium_teaser == 0 && !is_optin_url">
                            <Multiselect v-model="selectOptinItem" placeholder="Choose an opt-in" :options="['Free Optin 1', 'Free Optin 2', 'Free Optin 3', 'Free Optin 4']"></Multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action_wpr mt-5">
                <button type="button" class="btn cancel_btn" @click="handleTeaseOff();">Cancel</button>
                <button type="button" class="btn save_btn" @click="handleTeaseOn()">Tease</button>
            </div>
        </div>
    </div>

    <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    <wizard-plan v-model="workoutPlanWizard" :refresh-workout-plan="refreshWorkoutPlan" />
    <exercise-library v-model="isLibrary" />
    <new-workout-plan v-model="newWorkoutPlan" :refresh-asset="refreshWorkoutPlan" />
    <edit-thumbnail-asset v-model="editThumbnail" title="Plan" :selected-asset="activeWorkoutPlan" :refreshAsset="refreshWorkoutPlan" />
    <sort-asset v-model="sortWorkoutPlan" title="Plan" asset-type="workout-plans" :update-sorting="sortingFilter" />
    <import-share-link v-model="importWorkoutPlan" title="Plan" :refresh-asset="refreshWorkoutPlan" />
    <share-component v-model="shareWorkoutPlan" title="Plan" asset-title="workout-plan" :selected-asset="activeWorkoutPlan" />
    <asset-link v-model="workoutPlanLink" title="Plan" :selected-asset="activeWorkoutPlan" />
    <rename-plan v-model="renamePlan" :workout-plan="activeWorkoutPlan" :refresh-workout-plan="refreshWorkoutPlan" />
    <assign-contact v-model="assignContact" :ids="workoutPlanIds" :refresh-asset="refreshWorkoutPlan" />
    <unassign-contact v-model="unassignContact" :ids="workoutPlanIds" :refresh-asset="refreshWorkoutPlan" />
    <manage-contact v-model="manageContact" :selected-asset="activeWorkoutPlan" />
    <plan-analytics v-model="showAnalytics" :plan="selectedAnalytics"/>
    <preview-plan v-model="preview" :plan="activeWorkoutPlan" />
    <workout-categories v-model="manageCategories" :is-plan="1" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import StarRating from 'vue-star-rating'
    import Multiselect from '@vueform/multiselect';

    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))

    const ExerciseLibrary = defineAsyncComponent(() => import('@/pages/workout/components/ExerciseLibrary'))
    const UnassignContact = defineAsyncComponent(() => import('@/pages/workout/components/UnassignContact'))
    const PreviewPlan = defineAsyncComponent(() => import('@/pages/workout/components/plan/PreviewPlan'))

    const WizardPlan = defineAsyncComponent(() => import('@/pages/workout/components/plan/WizardPlan'))
    const RenamePlan = defineAsyncComponent(() => import('@/pages/workout/components/plan/RenamePlan'))
    const NewWorkoutPlan = defineAsyncComponent(() => import('@/pages/workout/components/plan/NewWorkoutPlan'))
    const ManageContact = defineAsyncComponent(() => import('@/pages/workout/components/plan/ManageContact'))
    const AssignContact = defineAsyncComponent(() => import('@/pages/workout/components/plan/AssignContact'))
    const PlanAnalytics = defineAsyncComponent(() => import('@/pages/workout/components/plan/PlanAnalytics'))
    const WorkoutCategories = defineAsyncComponent(() => import('@/pages/workout/components/Categories'))

    export default {
        name: "Plan Index",

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                tutorialVideo: '',
                assetFolder: false,
                actionList: false,
                actionList2: false,
                pageFilter: false,
                bulkOpt: false,
                bulkOpt2: false,
                workoutPlanWizard: false,
                newWorkoutPlan: false,
                activeWorkoutPlan: {},
                isLibrary: false,
                searchField: false,
                perPageFilter: 6,
                showContent: false,
                selectedPlans: [],
                filterTitle: 'Custom Arrangement',
                filters: [
                    { title: 'Custom Arrangement', field: 'order', order: 'desc'},
                    { title: 'A-Z', field: 'name', order: 'asc'},
                    { title: 'Z-A', field: 'name', order: 'desc'},
                    { title: 'Public', field: 'public', order: 'desc'},
                    { title: 'Private', field: 'private', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Date Modified', field: 'updated_at', order: 'desc'},
                    { title: 'Teased', field: 'is_teased', order: 'desc'},
                ],
                fullPreview: false,
                page: 1,
                moreOptionDropdown: false,
                isMoreOption: false,
                isTyping: false,
                search: '',
                editThumbnail: false,
                sortWorkoutPlan: false,
                importWorkoutPlan: false,
                shareWorkoutPlan : false,
                workoutPlanLink : false,
                renamePlan : false,
                assignContact: false,
                workoutPlanIds: [],
                unassignContact: false,
                manageContact: false,
                selectedAnalytics: null,
                showAnalytics: false,
                preview: false,
                env: {},
                assetFolderWatcher: 0,
                isLastBreadcrumb: false,
                isArchived: 0,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                mobile_filter: false,
                manageCategories: false,
                has_tease: 0,
                teaseConformation: 0,
                is_premium_teaser: 0,
                is_optin_url: false,
                optinURL: '',
                selectOptinItem: '',
            }
        },

        props: {
            tab: {
                type: String,
                Default: 'Workout'
            },
            switchTab: {
                type: Function,
                Default: () => {},
            },
        },

        components:{
            WizardPlan,
            ExerciseLibrary,
            NewWorkoutPlan,
            EditThumbnailAsset,
            SortAsset,
            ImportShareLink,
            ShareComponent,
            AssetLink,
            RenamePlan,
            AssignContact,
            UnassignContact,
            ManageContact,
            PlanAnalytics,
            PreviewPlan,
            AssetFolder,
            StarRating,
            Multiselect,
            WorkoutCategories,
        },

        watch: {
            workoutPlanWizard (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            fullPreview (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.getWorkoutPlans(vm.params);
            },

            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getWorkoutPlans(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getWorkoutPlans(vm.params);
                        }
                    }
                }
            },

            assetFolder (val) {
                const vm = this;

                vm.setIsAssetFolder(val);
            },
        },

        computed: {
            ...mapState ({
                params: state => state.workoutModule.planParams,
                plans: state => state.workoutModule.workoutPlans,
                workoutPlanLoader: state => state.workoutModule.workoutPlanLoader,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            document.getElementById('plan_index').onclick = function () {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }

            if (vm.isPreviousFolder) {
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                vm.folderParams.archived = vm.isArchived ? 1 : 0,
                vm.isLastBreadcrumb      = true;
                vm.assetFolder           = vm.isAssetFolder ? true : false;
            } else {
                vm.folderParams.archived = vm.params.archived ? 1 : 0;
            }

            vm.folderParams.parent_id = vm.params.folder_id ? vm.params.folder_id : 0;

            vm.setIsPreviousFolder(false);

            if (vm.$route.query && vm.$route.query.response && vm.$route.query.tab == 'plan') {
                vm.setDashboardParams({ key: 'search', value: vm.$route.query.search });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.getWorkoutPlans(vm.params).then((result) => {
                    if (result) {
                       const record =  vm.plans.data.filter(plan => plan.id == vm.$route.query.id)[0];

                       if (record) {
                           vm.handleAnalytics(record);
                       }
                    }
                });
            } else {
                vm.getWorkoutPlans(vm.params);
            }

            vm.env = process.env;
        },

        methods: {
            ...mapActions({
                getWorkoutPlans: 'workoutModule/getWorkoutPlans',
                duplicateWorkoutPlan: 'workoutModule/duplicateWorkoutPlan',
                deleteWorkoutPlan: 'workoutModule/deleteWorkoutPlan',
            }),

            ...mapMutations({
                setDashboardParams: 'workoutModule/SET_PLAN_DASHBOARD_PARAMS',
                resetDashboardParams: 'workoutModule/RESET_PLAN_DASHBOARD_PARAMS',
                setSelectedWorkoutPlan: 'workoutModule/SET_SELECTED_WORKOUT_PLAN',
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
                setIsAssetFolder: 'folderModule/SET_IS_ASSET_FOLDER_STATUS',
                setFolderBreadcrumb: 'folderModule/SET_FOLDER_BREADCRUMB',
            }),

            moreOption(id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            togglePerPageFilter () {
                const vm = this;
                vm.actionList         = false;
                vm.bulkActionDropdown = false;
                vm.modeFilterDropdown = false;

                const filter = vm.$refs['workout-plan-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            sortingFilter (filter) {
                const vm      = this;
                const orderBy = filter.field == 'ordering' ? 'order' : filter.field;

                vm.filterTitle      = filter.title;
                vm.selectedWorkouts = [];

                vm.setDashboardParams({ key: 'order_by', value: orderBy });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.getWorkoutPlans(vm.params);
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['workout-plan-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            showAction (ev) {
                const vm = this;

                vm.showContent = ev ? true : false;
            },

            handlePagination (page) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: page });
                vm.getWorkoutPlans(vm.params);
            },

            refreshWorkoutPlan () {
                const vm = this;

                vm.page          = 1;
                vm.search        = '';
                vm.selectedPlans = [];

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.getWorkoutPlans(vm.params);
            },

            toggleWorkoutPlanWizard (activeWorkoutPlan) {
                const vm = this;

                vm.setSelectedWorkoutPlan(activeWorkoutPlan);
                vm.workoutPlanWizard = true;
            },

            handleShareWorkoutPlan (workoutPlan) {
                const vm = this;

                if (workoutPlan.share_count == 0) {
                    vm.shareWorkoutPlan  = true;
                    vm.activeWorkoutPlan = workoutPlan;
                } else if (workoutPlan.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            handleDuplicatePlan (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate ${ ids.length > 1 ? 'these workout plans' : 'this workout plan'}`);
                        options.preConfirm = () => {
                                                return vm.duplicateWorkoutPlan({ ids }).then((result) => {
                                                    vm.search        = '';
                                                    vm.selectedPlans = [];

                                                    vm.setDashboardParams({ key: 'search', value: '' });
                                                    vm.getWorkoutPlans(vm.params);
                                                });
                                            };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one workout plan to duplicate!');
                }
            },

            handleDeletePlan (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this ${ ids.length > 1 ? 'these workouts' : 'this workout'}. Please type DELETE to remove this ${ ids.length > 1 ? 'these workout plans' : 'this workout plan'}.`, 'Delete');

                    options.confirmButtonColor = '#eb1414';
                    options.input = 'text';
                    options.inputAttributes = { placeholder: 'Enter DELETE' };

                    options.preConfirm = function () {
                                            return vm.deleteWorkoutPlan({ ids }).then((result) => {
                                                if (result) {
                                                    vm.refreshWorkoutPlan();
                                                }
                                            });
                                        }

                    options.willOpen        = function () {
                                                const button  = Swal.getConfirmButton();
                                                const input   = Swal.getInput()

                                                if (button) {
                                                    button.disabled = true;
                                                }

                                                input.oninput = (event) => {
                                                    const text = event.target.value;

                                                    if (/^[A-Z]/.test(text) == true && text === 'DELETE') {
                                                        Swal.enableButtons();
                                                    } else {
                                                        button.disabled = true;
                                                    }
                                                }
                                            }
                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one workout plan to delete!');
                }
            },

            handleAssignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.workoutPlanIds = ids;
                    vm.assignContact  = true;
                } else {
                    Toastr.error('Please select at least one workout plan to assign!');
                }
            },

            handleUnassignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.workoutPlanIds  = ids;
                    vm.unassignContact = true;
                } else {
                    Toastr.error('Please select at least one workout plan to unassign!');
                }
            },

            handlePlanPreview (id) {
                const vm = this;
                vm.fullPreview = true;

                vm.$router.push({name: 'WorkoutPreview', params: { id }});
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });

                vm.refreshWorkoutPlan();
            },


            updateFolderBreadcrumb (breadcrumb) {
                const vm = this;

                vm.setFolderBreadcrumb({ key: 'playbook', value: breadcrumb ? breadcrumb : [] });
            },

            handleAnalytics (plan) {
                const vm = this;

                vm.selectedAnalytics = plan;
                vm.showAnalytics = true;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkOpt = false;
            },

            closeBulkActionDropdown2() {
                const vm = this;

                vm.bulkOpt2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            handleTeaseConfirmation(){
                const vm = this;

                if(!vm.has_tease){
                    vm.teaseConformation = true;
                } else{
                    vm.teaseConformation = false;
                }

            },

            handleTeaseOn(){
                const vm = this;

                vm.has_tease = 1;
                vm.teaseConformation = false;
            },

            handleTeaseOff(){
                const vm = this;

                vm.has_tease = 0;
                vm.teaseConformation = false;
            }
        }
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .tab_sec {
        max-width: 1200px;
        margin: 0 auto 10px auto;
        border: 0;
    }

    .tab_sec li.active {
        border-radius: 5px;
    }

    .progress_container {
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list>li {
        padding: 20px;
        width: 33.333%;
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }

    .plybook_item .item_img .checkbox span {
        border-color: #8a8a8a;
    }

    .plybook_item .item_img .checkbox span i {
        color: #2C3E50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
    }
    .plybook_item .switch_list{
        position: absolute;
        right: -125px;
        bottom: 10px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transition: all 0.3s ease-in-out;
    }
    .plybook_item label.switch_option h5{
        font-size: 13px;
        color: #2c3e50;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    .plybook_item label.capsule_btn div{
        transform: scale(0.7);
    }
    .plybook_item .item_img .statistics li .pp-label{
        padding: 4px 10px;
        background: #2f7eed;
        color: #fff;
        border-radius: 9px;
        font-size: 9px;
        line-height: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    .plybook_item .item_img .statistics li .ratings{
        display: flex;
        gap: 2px;
    }
    .plybook_item .item_img .statistics li .ratings span{
        color: #2f7eed;
        font-size: 8px;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info>ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info>ul>li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics,
    .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    .plybook_item .item_img:hover .switch_list{
        right: 15px;
    }

    @keyframes dropMove2 {
        from {
            margin-bottom: 10px;
            opacity: 0;
        }

        to {
            margin-bottom: 1px;
            opacity: 1;
        }
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .modal.share_playbook .modal_header {
        padding: 20px 30px 0;
    }

    .modal.share_playbook .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row,
    .report_section .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li,
    .report_section .tab_row li {
        font-size: 13px;
        line-height: 15px;
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active,
    .report_section .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 100px;
        height: auto;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13);
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 12px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table td:not(:first-child, :last-child, :nth-child(2)) * {
        margin: 0 auto;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 15px;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i {
        color: #2f7eed;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .modal.redirect_modal .modal_container {
        height: 420px;
    }

    .modal.redirect_modal .section_ttl {
        font-size: 16px;
        line-height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
    }

    .modal.redirect_modal .section_ttl span {
        padding: 0 10px;
        background: #fff;
    }

    .modal.redirect_modal .section_ttl:after {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .redirect_list li {
        width: 33.333%;
        padding: 5px 8px;
    }

    .redirect_list li label h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        background: rgba(242, 163, 29, 0.05);
        border: 1px solid rgba(242, 163, 29, 0.3);
        color: #757575;
        filter: grayscale(1);
    }

    .redirect_list li label input[type=radio]:checked~h5 {
        filter: grayscale(0);
        color: #f2a31d;
    }

    .redirect_action {
        width: 100%;
        padding: 0 8px;
    }

    .modal.redirect_modal .action_wpr {
        margin-top: auto;
    }

    .ques_listing li {
        font-size: 14px;
        line-height: 19px;
        list-style-type: decimal;
        margin-left: 17px;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid #e9e9e9;
    }

    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    .modal.prompt .close_btn {
        position: absolute;
        right: -7px;
        left: auto;
        top: -7px;
        border: 1px solid #c7c7c7;
        background: rgba(50, 55, 59, 0.7);
        color: #fff;
        border-radius: 50%;
        font-size: 11px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        z-index: 1;
    }
    .modal.prompt .logo_img{
        max-height: 30px;
        width: auto;
        margin-bottom: 30px;
    }
    .modal.prompt .setting_wpr{
        max-width: 400px;
        width: 100%;
    }
    .modal.prompt .optin_type{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }
    .modal.prompt h4{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-right: 15px;
    }
    .modal.prompt h4 span{
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        display: block;
        padding-top: 3px;
    }
    .modal.prompt .action_wpr .btn.cancel_btn{
        background: #fff;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */

    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 600px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>